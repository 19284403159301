import { CheckoutSummary } from './checkout-summary'
import { ERROR_CODES, CheckoutCriticalError, displayAndLogCriticalError } from './checkout-critical-errors'
import { CheckoutExistingOrders } from './checkout-existing-orders'
import { parseAddressByFields, fieldsForCountry } from '../Utils/checkout-address'
import { AddressFields } from '../types'
import { CheckoutDigitalWallet } from './checkout-digital-wallet'
import { anyUpdatableOrders, updateCardByProductIdState } from '../CheckoutProductSelect/V2/checkoutProductSelect'
import { computed } from 'nanostores'
import { CheckoutCart } from 'src/Elements/Checkout/V2/types'
import { IntlTel_loadUtils } from 'javascript/lander/cf_utils'
import { CFFetch } from 'javascript/lander/utils/fetcher'

const INITIALIAZE_PAI_TIMEOUT = 60000
export class TimeoutError extends Error {}

declare global {
  interface Window {
    turnstileSiteKey: string
    turnstile: {
      render: (selector: string, options: { sitekey: string; callback: (token: string) => void }) => void
    }
  }
}

export const loadPAIDeps = (addLoader = true, shouldDisplayAndLogOnCriticalError = true): Promise<void> => {
  if (globalThis.Checkout.store.payment.state.get() != globalThis.Checkout.PaymentStates.START) return
  globalThis.Checkout.store.payment.state.set(globalThis.Checkout.PaymentStates.INITIALIZING)
  if (addLoader) globalThis.Checkout.store.payment.state.set(globalThis.Checkout.PaymentStates.LOADING)
  const js = document.createElement('script') as HTMLScriptElement
  js.src = 'https://framepay.payments.ai/rebilly.js'

  const errorHandler = (errorType, cause) => {
    if (shouldDisplayAndLogOnCriticalError) {
      const error = new CheckoutCriticalError(errorType, { cause })
      displayAndLogCriticalError(error)
    }
  }

  const promise = new Promise<void>((resolve, reject) => {
    js.onload = () => {
      intializePAI()
        .then(() => {
          resolve()
        })
        .catch((e) => {
          let errorType: string
          if (e instanceof TimeoutError) {
            errorType = ERROR_CODES.PAI_INITIALIZATION_TIMEOUT_ERROR
          } else {
            errorType = ERROR_CODES.UNEXPECTED_INITIALIZATION_ERROR
          }
          errorHandler(errorType, e)
          reject(new CheckoutCriticalError(errorType, { cause: e }))
        })
    }
    js.onerror = () => {
      const error = new Error('framepay JS onError load')
      errorHandler(ERROR_CODES.PAI_INITIALIZATION_TIMEOUT_ERROR, error)
      reject(new CheckoutCriticalError(ERROR_CODES.PAI_INITIALIZATION_TIMEOUT_ERROR, { cause: error }))
    }
  })
  document.head.appendChild(js)

  const css = document.createElement('link')
  css.href = 'https://framepay.payments.ai/rebilly.css'
  css.rel = 'stylesheet'
  document.head.appendChild(css)

  return promise
}

const initializeTurnstile = (): Promise<void> => {
  const turnstilejs = document.createElement('script') as HTMLScriptElement
  turnstilejs.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js'

  const promise = new Promise<void>((resolve) => {
    turnstilejs.onload = () => {
      const key = window.turnstileSiteKey
      window.turnstile.render('#cf-turnstile', {
        sitekey: key,
        callback: function (token: string) {
          globalThis.Checkout.turnstileToken = token
          resolve()
        },
      })
    }

    turnstilejs.onerror = (e) => {
      console.error('Turnstile error', e)
      // We only resolve on error because turnstile is experimental and we don't want to block the checkout
      resolve()
    }
  })

  document.head.appendChild(turnstilejs)

  return promise
}

const intializePAI = (): Promise<void> => {
  globalThis.Checkout.PaypalCallbacks = {
    onInit: () => {
      console.log('[PayPal] - onInit called')
      globalThis.Checkout.store.payment.paypal.state.set({ state: globalThis.Checkout.PaypalStates.INITIALIZED })
    },
    onApprove: (data, actions) => {
      console.log('[PayPal] - onApprove called', data, actions)
      globalThis.Checkout.store.payment.paypal.state.set({
        state: globalThis.Checkout.PaypalStates.PAYMENT_METHOD_APPROVED,
      })
    },
    onError: (data, actions) => {
      console.log('[PayPal] - onError called', data, actions)

      globalThis.Checkout.store.payment.paypal.state.set({
        state: globalThis.Checkout.PaypalStates.ERROR,
        code: globalThis.Checkout.ErrorTypes.PAYPAL_CUSTOM_ERROR,
        message: 'Something unexpected happened',
      })
    },
    onCancel: (data, actions) => {
      console.log('[PayPal] - onCancel called', data, actions)

      globalThis.Checkout.store.payment.paypal.state.set({
        state: globalThis.Checkout.PaypalStates.ERROR,
        code: globalThis.Checkout.ErrorTypes.PAYPAL_DECLINED_ERROR,
      })
    },
    onClick: (data, actions) => {
      console.log('[PayPal] - onClick called', data, actions)
      globalThis.Checkout.store.payment.paypal.state.set({
        state: globalThis.Checkout.PaypalStates.ADDING_PAYMENT_METHOD,
      })
    },
  }
  const checkoutElement = document.querySelector('[data-page-element="Checkout/V2"]')
  const checkoutComponent = (checkoutElement as any).cf2_instance
  const inputColor = getComputedStyle(checkoutElement).getPropertyValue('--input-color')
  const fontFamily = getComputedStyle(checkoutElement).getPropertyValue('--multiple-payments-font-family')
  const fontSize = getComputedStyle(checkoutElement).getPropertyValue('--multiple-payments-font-size')
  const rebillyKeys = document.getElementById('payment-gateway-keys')
  const publishableKey = rebillyKeys.getAttribute('data-rebilly-publishable-key')
  const organizationId = rebillyKeys.getAttribute('data-rebilly-organization-id')
  const websiteId = rebillyKeys.getAttribute('data-rebilly-website-id')
  const currency = rebillyKeys.getAttribute('data-rebilly-currency')
  const rebillyConfig = {
    publishableKey: publishableKey,
    organizationId: organizationId,
    kountAccountId: '700000', // This is for capturing kount fraud sessions
    websiteId: websiteId,
    // NOTE: need to add below otherwise this error happens: transactionData must contain an amount to fetch methods
    transactionData: {
      currency: currency || 'USD',
      amount: 1,
      label: 'Product Purchase',
    },
    icon: {
      color: inputColor,
    },
    style: {
      base: {
        color: inputColor,
        fontFamily: fontFamily,
        fontSize: fontSize,
        '::placeholder': {
          color: inputColor,
          fontFamily: fontFamily,
          fontSize: fontSize,
        },
      },
    },
    placeholders: {
      card: {
        number: checkoutComponent.cardPaymentPlaceholders.number,
        expiration: checkoutComponent.cardPaymentPlaceholders.expiration,
        cvv: checkoutComponent.cardPaymentPlaceholders.cvv,
      },
    },
  }

  CheckoutDigitalWallet.initialize()
  const promise = new Promise<void>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new TimeoutError(`Timeout error: Rebilly took more than ${INITIALIAZE_PAI_TIMEOUT} to load`))
    }, INITIALIAZE_PAI_TIMEOUT)

    globalThis.Rebilly.on('ready', () => {
      if (timeout) clearTimeout(timeout)
      try {
        globalThis.Checkout.store.payment.state.set(globalThis.Checkout.PaymentStates.INITIALIZED)
        resolve()
      } catch (e) {
        reject(e)
      }
    })

    globalThis.Rebilly.on('error', (err) => {
      console.error('Rebilly error', err)
      reject(err)
    })
  })

  globalThis.Rebilly.initialize(rebillyConfig)
  return promise as Promise<void>
}

type Options = {
  hasPhoneNumber: boolean
  billingFields: AddressFields
  shippingFields: AddressFields
}

const initializeCountries = (options: Options): Promise<void> => {
  return Promise.all(
    [
      globalThis
        .CFFetch(
          '/cf_countries_states.json',
          {
            headers: { 'Content-Type': 'application/json' },
          },
          { retries: 3, shouldCaptureServerError: true }
        )
        .then(function (res) {
          return res.json()
        })
        .catch((e) => {
          throw new CheckoutCriticalError(ERROR_CODES.FETCH_COUNTRIES_STATES_ERROR, {
            cause: e,
          })
        }),
      options.hasPhoneNumber &&
        IntlTel_loadUtils().catch((e) => {
          throw new CheckoutCriticalError(ERROR_CODES.FETCH_PHONE_UTILS_ERROR, {
            cause: e,
          })
        }),
    ].filter(Boolean)
  ).then(([countryStateReqResponse]) => {
    globalThis.Checkout.allCountries = countryStateReqResponse.result

    const defaultCountryCode = 'US'

    const countryData =
      globalThis.Checkout.allCountries.find((c) => c.iso2 == globalThis.cfVisitorData.country) ??
      globalThis.Checkout.allCountries.find((c) => c.iso2 == defaultCountryCode)

    const stateData =
      countryData.regions?.find((r) => r.iso2 == globalThis.cfVisitorData.regionCode) ?? countryData.regions?.[0]

    // TODO: check if this works for default country code case
    const country = countryData.iso2
    const state = stateData?.state_code

    globalThis.Checkout.contactLocale = {
      country: country,
      state: state,
    }

    const initialShipping = globalThis.Checkout.store.shipping.get()
    globalThis.Checkout.store.shipping.set({
      ...(initialShipping ?? {}),
      country: initialShipping?.country ?? country,
      state: initialShipping?.state ?? state,
    })

    let initialBilling = globalThis.Checkout.store.billing.get()
    if (globalThis.Checkout.store.checkout.mode.get() == 'guest') {
      initialBilling = parseAddressByFields(initialBilling, options.billingFields)
    }
    globalThis.Checkout.store.billing.set(initialBilling)

    globalThis.Checkout.store.phoneNumberInitialized.set(true)
  })
}

const checkFeatureFlags = (): Promise<void> => {
  return CFFetch('/user_pages/api/v1/checkouts/feature_flags.json', {}, { retries: 3, shouldCaptureServerError: true })
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      globalThis.Checkout.store.featureFlags.isShippingEnabled.set(res.feature_flags.is_shipping_enabled)
    })
    .catch((e) => {
      throw new CheckoutCriticalError(ERROR_CODES.FETCH_FEATURE_FLAGS_ERROR, {
        cause: e,
      })
    })
}

export const initializeMachine = (options: Options): void => {
  globalThis.Checkout.store.state.listen((newState) => {
    switch (newState) {
      case globalThis.Checkout.StoreStates.INITIALIZING: {
        const isGuest = globalThis.Checkout.store.checkout.mode.get() == 'guest'
        const isSaved = globalThis.Checkout.store.checkout.mode.get() == 'saved'
        const isOTO = globalThis.Checkout.store.checkout.mode.get() == 'oto'
        const paymentMethods = globalThis.Checkout.store.paymentMethods.get()
        const oneStepCheckout = document.querySelector('[data-page-element="Checkout/V2"][data-total-steps="1"]')
        const expressPaymentAvailable =
          globalThis.globalResourceData.expressEnabledPayments &&
          globalThis.globalResourceData.expressEnabledPayments.length > 0
        const shouldIntializePAI =
          (oneStepCheckout && isGuest) ||
          (!paymentMethods.length && (isSaved || isOTO)) ||
          (expressPaymentAvailable && isSaved)
        const shouldInitializeTurnstile = window.turnstileSiteKey && window.turnstileSiteKey.length

        Promise.all(
          [
            checkFeatureFlags(),
            shouldIntializePAI && loadPAIDeps(false, false),
            shouldInitializeTurnstile && initializeTurnstile(),
            initializeCountries(options),
            !isGuest && Object.keys(globalThis.Checkout.productsById).length > 0 && CheckoutExistingOrders.fetch(),
          ].filter((v) => !!v)
        )
          .then(() => {
            globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.INITIALIZED)
          })
          .catch((e) => {
            if (e instanceof CheckoutCriticalError) {
              displayAndLogCriticalError(e)
            } else {
              const initializationError = new CheckoutCriticalError(ERROR_CODES.UNEXPECTED_INITIALIZATION_ERROR, {
                cause: e,
              })
              displayAndLogCriticalError(initializationError)
            }
          })

        break
      }
      case globalThis.Checkout.StoreStates.INITIALIZED: {
        CheckoutSummary.sendOrderPreview()
        // NOTE: Change mode to filling form after all callbacks
        // for Initialized have been executed.
        setTimeout(() => {
          globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.FILLING_FORM)
        })
        break
      }
      case globalThis.Checkout.StoreStates.FILLING_FORM: {
        break
      }
      case globalThis.Checkout.StoreStates.SUBMITTED: {
        break
      }
    }
  })
  // # endregion state machine listeners

  // # region global listeners
  globalThis.Checkout.store.submitting.listen((submitting) => {
    const state = submitting.state
    switch (state) {
      case globalThis.Checkout.SubmittingStates.ERROR: {
        globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.FILLING_FORM)
        break
      }
      case globalThis.Checkout.SubmittingStates.DONE: {
        globalThis.Checkout.store.state.set(globalThis.Checkout.StoreStates.SUBMITTED)
        break
      }
    }
  })

  globalThis.Checkout.store.billing.listen((billing) => {
    const fields = globalThis.Checkout.store.billingFields.get()
    const addressFields = fieldsForCountry(fields, billing.country)
    globalThis.Checkout.store.billingFields.set(addressFields)
  })

  globalThis.Checkout.store.payment.paypal.state.listen((submitting) => {
    const state = submitting.state
    switch (state) {
      case globalThis.Checkout.PaypalStates.ERROR: {
        setTimeout(() => {
          globalThis.Checkout.store.payment.paypal.state.set({ state: globalThis.Checkout.PaypalStates.INITIALIZED })
        }, 3000)
        break
      }
    }
  })

  globalThis.Checkout.store.checkout.mode.listen((mode) => {
    if (mode === 'guest') {
      const cart = globalThis.Checkout.computed.checkoutCart.get() as CheckoutCart
      CheckoutExistingOrders.clearUpdatableOrders(cart.items[0]?.product_id)

      // #region reset contact data
      globalThis.Checkout.store.contact.set({})
      globalThis.Checkout.store.shipping.set({
        country: globalThis.Checkout.contactLocale.country,
        state: globalThis.Checkout.contactLocale.state,
      })

      const newBillingData = parseAddressByFields(globalThis.Checkout.contactLocale, options.billingFields)
      globalThis.Checkout.store.billing.set(newBillingData)
      globalThis.Checkout.store.payment.id.set(null)
      // #endregion reset contact data
    }
  })

  // Adds PAI when the user clicks to add a new payment
  globalThis.Checkout.store.payment.id.listen((newPaymentId) => {
    if (!newPaymentId) loadPAIDeps()
  })
  globalThis.Checkout.computed.isOTORetrialPayment.listen((isOTORetrialPayment) => {
    if (isOTORetrialPayment) loadPAIDeps()
  })

  const checkoutStatesChangedByCartItems = {
    [globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE]: true,
    [globalThis.Checkout.CheckoutStates.REACTIVATE]: true,
  }

  computed([globalThis.Checkout.computed.modeLeaveEnterEvent, globalThis.Checkout.store.state], (ev, state) => {
    if ([globalThis.Checkout.StoreStates.START, globalThis.Checkout.StoreStates.INITIALIZING].includes(state)) return
    const { enter } = ev
    if (enter && !checkoutStatesChangedByCartItems[enter]) {
      globalThis.Checkout.store.checkout.lastModeIndependentOfCartItems.set(enter)
    }

    // Add PAI when entering on guest mode and page has a one step checkout
    // Or when entering on saved mode and the contact does not have any saved payments
    const oneStepCheckout = document.querySelector('[data-page-element="Checkout/V2"][data-total-steps="1"]')
    if (enter === 'guest' && oneStepCheckout) {
      loadPAIDeps()
    }
    if (enter === 'saved') {
      const contactPaymentMethod = globalThis.Checkout.store.paymentMethods.get()
      if (!contactPaymentMethod?.length) {
        loadPAIDeps()
      }
    }
  }).subscribe(() => {})

  globalThis.Checkout.computed.checkoutCart.listen((cart) => {
    const currentMode = globalThis.Checkout.store.checkout.mode.get()

    let cartState
    cart.items.forEach(({ type }) => {
      if (type) {
        if (cartState) {
          throw new Error(`Cart has more than one item of type ${type}`)
        }
        cartState = type
      }
    })

    if (cartState && cartState != currentMode) {
      globalThis.Checkout.store.checkout.mode.set(cartState)
    } else if (
      !cartState &&
      [globalThis.Checkout.CheckoutStates.UPGRADE_DOWNGRADE, globalThis.Checkout.CheckoutStates.REACTIVATE].includes(
        currentMode
      )
    ) {
      const lastModeIndependentOfCartItems = globalThis.Checkout.store.checkout.lastModeIndependentOfCartItems.get()
      if (lastModeIndependentOfCartItems && lastModeIndependentOfCartItems != currentMode) {
        globalThis.Checkout.store.checkout.mode.set(lastModeIndependentOfCartItems)
      }
    }
  })

  let lastBilling
  globalThis.Checkout.store.billing.listen((billing) => {
    if (globalThis.Checkout.store.billingApiErrorsByField.get()) globalThis.Checkout.store.billingApiErrorsByField.set()
    const backfilledBillingFromApi = options.billingFields.length == 2 && Object.keys(billing).length > 2
    const updatedZipForSameAddress = !billing.id && lastBilling?.zip != billing.zip
    // NOTE: If zip code changes for a new address, cleans up other fields calculated by API
    // in order to recalculate them by back-end again, otherwise, we would be storing wrong data here
    if (backfilledBillingFromApi && updatedZipForSameAddress) {
      const newBillingAddress = {
        country: billing.country,
        zip: billing.zip,
      }
      globalThis.Checkout.store.billing.set(newBillingAddress)
    }
    lastBilling = billing
  })
  // # endregion global listeners
}
